.ScoreGuages .principle-score-container {
  height: 120px;
  width: 120px;
}

.ScoreGuages .principle-score-container.sm {
  height: 108px;
  width: 108px;
}

@media (min-width: 576px) {
  .ScoreGuages .principle-score-container {
    height: 108px;
    width: 108px;
  }

  .ScoreGuages .principle-score-container.sm {
    height: 108px;
    width: 108px;
  }
}