/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
}

.modal.left .modal-header,
.modal.right .modal-header,
.modal.left .modal-body,
.modal.right .modal-body,
.modal.right .modal-footer,
.modal.left .modal-footer {
  padding: 32px 32px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: 0px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal.left .modal-content,
.modal.right .modal-content {
  border-radius: 0;
  border: none;
}

@media (min-width: 768px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 480px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    left: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

@media (min-width: 992px) {
  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 640px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    width: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

.pointer {
  cursor: pointer;
}