.tags-wrapper {
  & .has-aux .form-control {
    padding-right: 0px !important;
  }

  & .rbt-input-main {
    width: 30px !important;
  }
}

.typehead-multi-select-active {
  & .rbt-input-multi {
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #008219;
    background: #e9f2e8;
  }
  & .rbt-token {
    color: #008219;
    border: 1px solid #008219;
    background: #fff;
  }

 
}

.tag-dropdown {
  & .dropdown-menu {
    top: 36px !important;
    width: 200px !important;
  }
}
