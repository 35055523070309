$primary: #007a5f;
$white: #fff;
$gray: #c7c7c7;
$black: #000;

.table-scroll {
  height: 130px;
  overflow: auto;
}

.react-datepicker {
  font-size: 14px !important;
  color: $gray !important;
  padding-top: 10px !important;

  &__header {
    background-color: $white !important;
    padding-top: 0px !important;
    border: none !important;
  }

  &__day-name {
    color: $primary !important;
    font-size: 14px !important;
    font-weight: bold;
  }

  &__day {
    color: $black !important;
    font-size: 13px !important;

    &--keyboard-selected,
    &--selected {
      background: $primary !important;
      color: $white !important;
    }
    &--disabled {
      color: $gray !important;
    }
    &--in-selecting-range {
      background: $primary !important;
      color: $white !important;
    }
    &--in-range {
      background: $primary !important;
      color: $white !important;
    }
  }
}
.react-datepicker__navigation {
  padding-top: 15px !important;
}


.react-datepicker-wrapper{
  width: 100%;

  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: transparent;
    color: #7f7f7f;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    
    font-family: "Material Symbols Outlined";
    font-size: 24px;
    content: "\e5cd";
    margin: -2px 0px 0px;
  }
}