.dropdown .dropdown-toggle::after {
  font-family: "Material Symbols Outlined";
  font-size: 20px;
  color: inherit;
  vertical-align: middle;
  line-height: 1;
  content: "";
  margin: 0px 0px 0px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;

  border-left: none;
  border-right: none;
  border-top: none;
  /* padding-top: 0.3em; */
  padding-left: 0.1em;
  /* padding-right: 0.3em; */

  float: right;

}

.dropdown-menu {
  max-height: 500px;
  overflow-x: auto;
}

.action-item {
  .dropdown-menu {
    width: 300px;
    padding-bottom: 0px;
  }
  .dropdown-toggle::after {
    padding-top: 0.15em;
  }
}

