.secondary-navbar {
  background-color: #eaecef;
}
.dropdown {
  .secondary-dropdown {
    width: 200px;
    display: block;
    margin-top: 3px;
  }
}
.secondary-navbar .nav-link.active {
  background-color: #d3dfd4;
  color: #000000 !important;
}
