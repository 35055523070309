.typeahead-input {
  & input,
  & input:focus {
    border: none !important;
  }
  display: flex;
  align-items: center;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.typeahead-active-input {
  & input,
  & input:focus {
    border: none !important;
    color: #018219 !important;
    outline: 0;
    box-shadow: none !important;
    background-color: rgba(#018219, 0) !important;
  }
  color: #018219 !important;
  outline: 0;
  box-shadow: none !important;
  border: 1px solid #018219;
  border-radius: 0.25rem;
}
