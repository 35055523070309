.material-symbols,
.material-symbols-outlined {
  vertical-align: middle;
  line-height: inherit;
  margin-top: -2px;

  &.md-10 {
    font-size: 10px;
  }

  &.md-11 {
    font-size: 11px;
  }

  &.md-12 {
    font-size: 12px;
  }

  &.md-13 {
    font-size: 13px;
  }

  &.md-14 {
    font-size: 14px;
  }

  &.md-15 {
    font-size: 15px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-22 {
    font-size: 22px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}