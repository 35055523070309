.TextLogo {
  .Logo {
    a {
      text-decoration: none;
      h1 {
        font-size: 8ch;
        color: #4d4d4d; // #007fff;
      }
    }
  }

  .VerticalLogo {
    filter: brightness(0);
  }
}
