.w-35 {
  width: 35%;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}
