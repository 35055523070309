@media (min-width: 992px) {
  .filters-wrapper {
    & .col-lg-2 {
      width: 19% !important;
    }
  }
}
.active-filter {
  & input,
  & input:focus {
    color: #018219;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #018219;
    background-color: rgba(#018219, 0) !important;
  }
}


.date-filter {
  & .react-datepicker__close-icon::after {
    padding-top: 10px !important;
  }
}

.typehead-menu-alignment {
  margin-top: 36px !important;
  width: 100%;
}
