.notification-menu{
  width: 30rem;
}

/* width */
.notification-menu::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.notification-menu::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
.notification-menu::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.notification-menu::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
