.multiple-tags-input {
  outline: 0;
  box-shadow: none !important;
  border: 1px solid #008219 !important;
  background: #e9f2e8 !important;

  & .assignee-tag-badge {
    color: #008219;
    border: 1px solid #008219 !important;
    background: #fff !important;
  }

  & .form-control {
    background: #e9f2e8 !important;
    box-shadow: none !important;
  }
}

.tags-input {
  & input,
  & input:focus {
    box-shadow: none !important;
  }
}
