.rbt-token .rbt-token-remove-button {
  border: none;
  background: transparent;
  &::after {
    font-family: "Material Symbols Outlined";
    font-size: 16px;
    color: #aaa;
    vertical-align: middle;
    line-height: 1;
    content: "\e5cd";
    margin: -2px 0px 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
  }
  .rbt-close-content {
    display: none;
  }
}

.rbt-aux .rbt-close {
  margin-top: 0;
  pointer-events: auto;
  .rbt-close-content {
    display: none;
  }
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-size: 1.5rem;
}
