.MainHeader {
  .org-item {
    min-width: 200px;
  }
  .navbar-brand {
    img {
      margin-top: -10px;
      margin-bottom: -10px;
      height: 48px;
    }
  }
  .OrganizationToggler {
    background-color: #d3dfd4;
    .dropdown-toggle.nav-link {
      color: black;
    }
  }
}
