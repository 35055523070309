.start-25 {
  left: 25%;
}
.start-35 {
  left: 35%;
}

.top-n25 {
  top: -25%;
}
.top-25 {
  top: 25%;
}
.top-n10 {
  top: -10%;
}
.top-10 {
  top: 10%;
}
.top-5 {
  top: 5%;
}

.bottom-25 {
  bottom: 25%;
}

.end-25 {
  right: 25%;
}

.bg-gradient-gray {
  background: linear-gradient(180deg, #d2d9df 0%, #f8f9fa 100%);
}
